import BookIcon from "@mui/icons-material/Book";
import FestivalIcon from "@mui/icons-material/Festival";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./HomePage.css";

function HomePage() {
  const s3BucketUrl = process.env.REACT_APP_AWS_S3_BUCKET_URL;

  return (
    <Box
      sx={{
        backgroundImage: `url("${s3BucketUrl}/dancestyle_images/home-page-wallpaper-aachener-weiher-mirrored.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "92vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flex: 1,
        overflowY: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Typography // Add a title
          variant="h2"
          component="h1"
          sx={{
            color: "white",
            textShadow: "1px 1px 10px black",
            fontSize: { xs: "2rem", md: "3rem" },
            mb: 5,
            padding: "4px 8px",
            fontWeight: "bold",
          }}
        >
          Explore DanceMaps
        </Typography>

        <Grid container spacing={10} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Button
              component={Link}
              to="/festival"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<CalendarMonthIcon />}
              sx={{ py: 2 }}
            >
              Festivals
            </Button>
            <Typography
              variant="body2"
              align="center"
              mt={1}
              sx={{
                fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                color: "white",
                textShadow: "1px 1px 5px black",
                padding: "4px 8px",
              }}
            >
              Discover upcoming dance festivals around the world.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component={Link}
              to="/local_party"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<LocationOnIcon />}
              sx={{ py: 2 }}
            >
              Local Parties
            </Button>
            <Typography
              variant="body2"
              align="center"
              mt={1}
              sx={{
                fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                color: "white",
                textShadow: "1px 1px 5px black",
                padding: "4px 8px",
              }}
            >
              Find local dance parties and events near you.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component={Link}
              to="/dance_dictionary"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<BookIcon />}
              sx={{ py: 2 }}
            >
              Dictionary
            </Button>
            <Typography
              variant="body2"
              align="center"
              mt={1}
              sx={{
                fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                color: "white",
                textShadow: "1px 1px 5px black",
                padding: "4px 8px",
              }}
            >
              Explore a dance dictionary with figures and terminology.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component={Link}
              to="/songs_charts"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<QueueMusicIcon />}
              sx={{ py: 2 }}
            >
              Songs Charts
            </Button>
            <Typography
              variant="body2"
              align="center"
              mt={1}
              sx={{
                fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                color: "white",
                textShadow: "1px 1px 5px black",
                padding: "4px 8px",
              }}
            >
              Browse the latest and most popular dance songs.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomePage;
