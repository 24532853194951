import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { usePartyStore } from "../../store/localPartyStore.js";

import {
  AddLocationAlt,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Link as LinkIcon,
  LocationOn as LocationOnIcon,
} from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/de";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import { debounce } from "lodash";
import { useMapEvents } from "react-leaflet";
import { useScreenSize } from "../../context/screenSizeContext";
import AddPartyForm from "./AddPartyForm";
import markerIconPng from "./marker-icon.png";
import PartyFavoriteButton from "./PartyFavoriteButton";

const LocalPartyMap = ({
  soonestParties,
  disabledDates,
  fetchParties,
  showFavoritesOnly,
  setShowFavoritesOnly,
  dateFilter,
  setDateFilter,
  partyTypeFilter,
  setPartyTypeFilter,
}) => {
  const markerPosition = usePartyStore((state) => state.markerPosition);
  const [showForm, setShowForm] = useState(false);
  const latitude = usePartyStore((state) => state.latitude);
  const longitude = usePartyStore((state) => state.longitude);

  const isSmallScreen = useScreenSize();

  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
    // Notify the parent component about the change
  };

  const handlePartyTypeFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setPartyTypeFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleMapBoundsChanged = (bounds) => {
    fetchParties(bounds);
  };

  const debouncedHandleMapBoundsChanged = debounce(handleMapBoundsChanged, 500);

  const MapEventsComponent = () => {
    const map = useMapEvents({
      zoomend: () => {
        const bounds = map.getBounds();
        debouncedHandleMapBoundsChanged(bounds);
      },
    });
    return null;
  };

  const partyTypeOptions = [
    "Bachata",
    "Kizomba",
    "Salsa",
    "West Coast Swing",
    "Zouk",
  ];

  const handleDateFilterChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      // Check if newValue is valid
      setDateFilter(newValue.format("YYYY-MM-DD")); // Only update if valid
    } else {
      setDateFilter(""); // Clear the date if invalid or null
    }
  };

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={12}
      style={{ height: isSmallScreen ? "84vh" : "92vh" }}
      zoomControl={false}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <MarkerClusterGroup
        key={soonestParties.map((party) => party.id).join(",")}
        showCoverageOnHover={false}
      >
        {soonestParties.map((party) => (
          <Marker
            key={party.id}
            position={[party.latitude, party.longitude]}
            icon={customIcon}
          >
            <Popup closeOnClick={false} autoClose={false}>
              <Card sx={{ maxWidth: 200, position: "relative" }}>
                <Box
                  sx={{ position: "relative", top: 8, right: -5, zIndex: 1 }}
                >
                  <PartyFavoriteButton party={party} />
                </Box>
                <CardContent sx={{ padding: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: 16,
                      marginBottom: 1,
                      maxWidth: 200,
                    }}
                  >
                    {party.name}
                  </Typography>
                  {party.organizer && (
                    <>
                      <Typography
                        variant="h3"
                        color="text.secondary"
                        sx={{ fontSize: 12, marginBottom: 0.25 }}
                      >
                        Organizer: {party.organizer}
                      </Typography>
                      <Divider sx={{ my: 0.5 }} />
                    </>
                  )}
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    sx={{ fontSize: 12, marginBottom: 0.25 }}
                  >
                    {party.type}
                  </Typography>
                  <Divider sx={{ my: 0.5 }} />
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    sx={{ fontSize: 12 }}
                  >
                    {dayjs(party.date).format("DD.MM.YYYY")}
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 1 }}>
                  {party.link && (
                    <IconButton
                      size="small"
                      href={party.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontSize: 12 }}
                    >
                      {party.link.includes("facebook") ? (
                        <FacebookIcon />
                      ) : party.link.includes("instagram") ? (
                        <InstagramIcon />
                      ) : (
                        <LinkIcon />
                      )}
                    </IconButton>
                  )}
                  {party.address && (
                    <IconButton
                      size="small"
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        party.address
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontSize: 12 }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
      {!isSmallScreen && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 1000,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "1rem 1rem 0.5rem 1rem",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="party-type-filter-label">Filter</InputLabel>
              <Select
                labelId="party-type-filter-label"
                id="party-type-filter"
                multiple
                value={partyTypeFilter}
                input={<OutlinedInput label="Party Type" />}
                renderValue={(selected) =>
                  selected.length > 0 ? selected.join(", ") : "All"
                }
                onChange={handlePartyTypeFilterChange}
              >
                {partyTypeOptions.map((typeOption) => (
                  <MenuItem key={typeOption} value={typeOption}>
                    <Checkbox
                      checked={partyTypeFilter.indexOf(typeOption) > -1}
                    />
                    <ListItemText primary={typeOption} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <DesktopDatePicker
                label="Date Filter"
                value={dateFilter ? dayjs(dateFilter) : null}
                closeOnSelect={true}
                onChange={(newValue) => {
                  if (newValue === null) {
                    setDateFilter("");
                  } else {
                    handleDateFilterChange(newValue);
                  }
                }}
                shouldDisableDate={(date) => {
                  return (
                    date < dayjs().subtract(1, "day") ||
                    !disabledDates.includes(date.format("YYYY-MM-DD"))
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                slotProps={{ field: { clearable: true } }}
                sx={{ width: 180 }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showFavoritesOnly}
                  onChange={handleFavoriteToggle}
                  name="showFavoritesOnly"
                  color="primary"
                />
              }
              label="Favorites Only"
            />
          </Box>
        </Box>
      )}
      {isSmallScreen ? ( // Conditionally render the button
        <IconButton
          sx={{
            position: "absolute",
            bottom: 50,
            left: 10,
            zIndex: 1000,
          }}
          variant="contained"
          onClick={() => setShowForm(true)}
        >
          {/* You can add an icon here if needed */}
          <AddLocationAlt />
        </IconButton>
      ) : (
        <div
          style={{
            position: "absolute",
            bottom: 10,
            left: 10,
            zIndex: 1000,
            padding: "1rem",
          }}
        >
          <Button variant="contained" onClick={() => setShowForm(true)}>
            Add Your Party
          </Button>
        </div>
      )}
      {showForm && <AddPartyForm onClose={() => setShowForm(false)} />}
      {markerPosition && (
        <Marker
          position={markerPosition}
          icon={
            new L.icon({
              iconUrl: markerIconPng,
              iconSize: ["auto", 40],
              iconAnchor: [12, 40],
            })
          }
        >
          {/* You can add a popup here if needed */}
          <Popup>Party Location</Popup>
        </Marker>
      )}
      {/* <RecenterMap /> */}
      <MapEventsComponent />
    </MapContainer>
  );
};

export default LocalPartyMap;
