import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Alert,
  Box,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import "dayjs/locale/de";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import "react-leaflet-markercluster/dist/styles.min.css";
import { useScreenSize } from "../../context/screenSizeContext";
import { useFavoritesStore } from "../../store/favoritesStore";
import { usePartyStore } from "../../store/localPartyStore.js";
import LocalPartyList from "./LocalPartyList.js";
import LocalPartyMap from "./LocalPartyMap";
import PartyFilter from "./PartyFilter";
const LocalParty = () => {
  const [parties, setParties] = useState([]);
  const [partyTypeFilter, setPartyTypeFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState("");
  const setLatitude = usePartyStore((state) => state.setLatitude);
  const setLongitude = usePartyStore((state) => state.setLongitude);
  const snackbarMessage = usePartyStore((state) => state.snackbarMessage);
  const snackbarSeverity = usePartyStore((state) => state.snackbarSeverity);
  const snackbarOpen = usePartyStore((state) => state.snackbarOpen);
  const setSnackbarOpen = usePartyStore((state) => state.setSnackbarOpen);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const favorites = useFavoritesStore((state) => state.favorites);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [view, setView] = useState("map");
  const isSmallScreen = useScreenSize();

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const partyTypeOptions = [
    "Bachata",
    "Kizomba",
    "Salsa",
    "West Coast Swing",
    "Zouk",
  ];

  const fetchParties = async (bounds) => {
    if (!bounds) {
      bounds = L.latLngBounds(
        L.latLng(50.904656637181766, 6.890487670898438),
        L.latLng(50.98231659641195, 7.016315460205079)
      );
    }

    let url = `${process.env.REACT_APP_API_URL}/parties/`;
    url += `?southWestLatitude=${bounds._southWest.lat}&southWestLongitude=${bounds._southWest.lng}&northEastLatitude=${bounds._northEast.lat}&northEastLongitude=${bounds._northEast.lng}`;

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setParties(data);
      });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          const bounds = L.latLngBounds(
            L.latLng(
              position.coords.latitude - 0.1,
              position.coords.longitude - 0.1
            ),
            L.latLng(
              position.coords.latitude + 0.1,
              position.coords.longitude + 0.1
            )
          );
          fetchParties(bounds);
        },
        (error) => {
          fetchParties();
          console.error("Error getting location:", error);
        }
      );
    } else {
      fetchParties(); // Call fetchParties with default bounds if no geolocation
    }
  }, []);

  const filteredParties = parties.filter((party) => {
    const isFavorite = favorites["localParties"].includes(party.id);
    if (showFavoritesOnly) {
      return isFavorite;
    }

    if (partyTypeFilter.length === 0 && !dateFilter && !showFavoritesOnly) {
      return true; // Show all if no filters are active
    }

    const typeMatch =
      partyTypeFilter.length === 0 ||
      partyTypeFilter.some((filterType) => party.type.includes(filterType));

    const dateMatch = !dateFilter || party.date === dateFilter;
    return typeMatch && dateMatch;
  });

  const disabledDates =
    partyTypeFilter.length === 0
      ? parties.map((party) => party.date) // Use all parties if no type filter
      : filteredParties.map((party) => party.date);

  const handlePartyTypeFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setPartyTypeFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleDateFilterChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      // Check if newValue is valid
      setDateFilter(newValue.format("YYYY-MM-DD")); // Only update if valid
    } else {
      setDateFilter(""); // Clear the date if invalid or null
    }
  };

  // Group parties by type and location
  const groupedParties = filteredParties.reduce((acc, party) => {
    const key = `${party.type}-${party.latitude}-${party.longitude}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(party);
    return acc;
  }, {});

  // Get the soonest party from each group
  const soonestParties = Object.values(groupedParties).map((group) => {
    return group.sort((a, b) => new Date(a.date) - new Date(b.date))[0];
  });

  return (
    <>
      {isSmScreen && (
        <Box
          sx={{
            height: "8vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {showFavoritesOnly ? (
            <FavoriteIcon
              onClick={handleFavoriteToggle}
              style={{ cursor: "pointer" }}
              sx={{
                color: "red",
                margin: 1,
              }}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={handleFavoriteToggle}
              style={{ cursor: "pointer" }}
              sx={{
                color: "black",
                margin: 1,
              }}
            />
          )}
          {/* Date Filter */}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <MobileDatePicker
              label="Date"
              value={dateFilter ? dayjs(dateFilter) : null}
              inputFormat="dd.mm.yyyy"
              closeOnSelect={true}
              onChange={(newValue) => {
                if (newValue === null) {
                  setDateFilter("");
                } else {
                  handleDateFilterChange(newValue);
                }
              }}
              shouldDisableDate={(date) => {
                return (
                  date < dayjs().subtract(1, "day") ||
                  !disabledDates.includes(date.format("YYYY-MM-DD"))
                );
              }}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
                textField: {
                  size: "small",
                  sx: {
                    "& .MuiInputBase-input": {
                      fontSize: isSmallScreen ? "0.8rem" : "1rem",
                      width: isSmallScreen ? "80px" : "100px",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
          {/* Filters */}
          <Box>
            <PartyFilter
              partyTypeFilter={partyTypeFilter}
              setPartyTypeFilter={setPartyTypeFilter}
              partyTypeOptions={partyTypeOptions}
            />
          </Box>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="View mode"
            sx={{ margin: 1 }}
          >
            <ToggleButton value="map" aria-label="map view">
              <MapIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      {view === "list" ? (
        <LocalPartyList soonestParties={soonestParties} />
      ) : (
        <LocalPartyMap
          soonestParties={soonestParties}
          disabledDates={disabledDates}
          fetchParties={fetchParties}
          showFavoritesOnly={showFavoritesOnly}
          setShowFavoritesOnly={setShowFavoritesOnly}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          partyTypeFilter={partyTypeFilter}
          setPartyTypeFilter={setPartyTypeFilter}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LocalParty;
