import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Contact from "./components/Contact.js";
import DanceStyle from "./components/DanceStyle";
import Dictionary from "./components/dictionary/Dictionary.js";
import DictionarySmall from "./components/dictionary/DictionarySmall.js";
import Disclaimer from "./components/Disclaimer";
import Festivals from "./components/festivals/Festivals";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import HomePageSmall from "./components/HomePageSmall";
import Impressum from "./components/Impressum.js";
import LocalParty from "./components/localParty/LocalParty.js";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Songs from "./components/songs/Songs";
import Soon from "./components/Soon";
import TermsOfService from "./components/TermsOfService";
// import icon from "./favicon.ico";
import { useScreenSize } from "./context/screenSizeContext";
import { useStore } from "./store/store.js";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2", // Your primary color
      },
      secondary: {
        main: "#f50057", // Your secondary color
      },
      // ... other color customizations
    },
    typography: {
      fontFamily: "Roboto, Arial, sans-serif", // Your font family
      // ... other typography customizations
    },
    // ... (spacing, breakpoints, etc.)
  });

  const danceStore = useStore();
  const selectedStyle = danceStore.style;

  useEffect(() => {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const selectedStyleParam = parts[parts.length - 1].replace("_map", "");
    const styleToUse = selectedStyle || selectedStyleParam;
    danceStore.setStyle(styleToUse);
  }, []);

  const handleStyleChange = (event) => {
    danceStore.setStyle(event.target.value);
  };
  const isSmallScreen = useScreenSize();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>DanceMaps - Your Ultimate Social Dance Companion</title>
        <meta
          name="description"
          content="Discover a world of social dance figures and exciting festivals. Remember your moves, find events near you, and connect with the global dance community."
        />
        <meta
          name="keywords"
          content="social dancing, social dance festivals, social dance figures, social dance tutorials, dancemaps, dance maps"
        />
        <meta
          property="og:title"
          content="DanceMaps - Your Ultimate Social Dance Companion"
        />
        <meta
          property="og:description"
          content="Discover a world of social dance figures and exciting festivals. Remember your moves, find events near you, and connect with the global dance community."
        />
        <meta property="og:url" content="https://dancemaps.net/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <BrowserRouter>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Header handleStyleChange={handleStyleChange} />
          <Box component="main" sx={{ minHeight: "92vh" }}>
            <Routes>
              <Route
                exact
                path="/"
                element={isSmallScreen ? <HomePageSmall /> : <HomePage />}
              />
              <Route exact path="/dance_dictionary" element={<DanceStyle />} />
              {/* <Route exact path="/about" element={<About />} /> */}
              <Route exact path="/contact" element={<Contact />} />
              <Route
                exact
                path={`/${selectedStyle}_map`}
                element={isSmallScreen ? <DictionarySmall /> : <Dictionary />}
              />
              <Route exact path={"/festival"} element={<Festivals />} />
              <Route exact path="/local_party" element={<LocalParty />} />
              <Route exact path="/ticket_trading" element={<Soon />} />
              <Route exact path="songs_charts" element={<Songs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/impressum" element={<Impressum />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
