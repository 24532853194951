import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import dayjs from "dayjs";
import "dayjs/locale/de";
import PartyFavoriteButton from "./PartyFavoriteButton";

const LocalPartyList = ({ soonestParties }) => {
  return (
    <List>
      {soonestParties.map((party) => (
        <ListItem key={party.id}>
          <Card sx={{ width: "100%" }}>
            <Box sx={{ position: "relative", top: 8, right: -5, zIndex: 1 }}>
              <PartyFavoriteButton party={party} />
            </Box>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  {party.name.charAt(0)}
                </Avatar>
              }
              title={party.name}
              subheader={
                <Typography variant="body2" color="text.secondary">
                  {party.organizer}
                </Typography>
              }
            />
            <CardContent>
              <Link
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  party.address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                color="inherit"
              >
                <Typography variant="body2" color="text.secondary">
                  <LocationOnIcon sx={{ mr: 0.5 }} />
                  {party.address}
                </Typography>
              </Link>
              <Typography variant="body2" color="text.secondary">
                <CalendarTodayIcon sx={{ mr: 0.5 }} />
                {dayjs(party.date).format("DD.MM.YYYY")}
              </Typography>
            </CardContent>
          </Card>
        </ListItem>
      ))}
    </List>
  );
};

export default LocalPartyList;
