import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  IconButton,
  Menu,
  Box,
  Chip,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import * as React from "react";
import { useState } from "react";
import "./FestivalsFilter.css";

function FestivalFilters({ selectedTypes, setSelectedTypes, filterTypes }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleTypeFilterChange = (event) => {
      const {
          target: { value, checked },
      } = event;

      setSelectedTypes((prevSelectedTypes) => {
          if (checked) {
              return [...prevSelectedTypes, value];
          } else {
              return prevSelectedTypes.filter((type) => type !== value);
          }
      });
  };

  return (
      <FormControl size="small" fullWidth>
          <IconButton
              aria-label="filter list"
              aria-controls={open ? 'festival-type-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
          >
              <FilterListIcon />
          </IconButton>
          <Menu
              id="festival-type-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                  'aria-labelledby': 'filter list',
              }}
          >
              {filterTypes.map((type) => (
                  <MenuItem key={type}>
                      <Checkbox
                          value={type}
                          checked={selectedTypes.indexOf(type) > -1}
                          onChange={handleTypeFilterChange}
                      />
                      <ListItemText primary={type} />
                  </MenuItem>
              ))}
          </Menu>
      </FormControl>
  );
}

export default FestivalFilters;