// PartyFilter.js

import {
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    IconButton,
    Menu,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import * as React from "react";
import { useState } from "react";

function PartyFilter({ partyTypeFilter, setPartyTypeFilter, partyTypeOptions }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePartyTypeFilterChange = (event) => {
        const {
            target: { value, checked },
        } = event;

        setPartyTypeFilter((prevPartyTypeFilter) => {
            if (checked) {
                return [...prevPartyTypeFilter, value];
            } else {
                return prevPartyTypeFilter.filter((type) => type !== value);
            }
        });
    };

    return (
        <FormControl size="small" fullWidth>
            <IconButton
                aria-label="filter list"
                aria-controls={open ? 'party-type-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <FilterListIcon />
            </IconButton>
            <Menu
                id="party-type-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'filter list',
                }}
            >
                {partyTypeOptions.map((typeOption) => (
                    <MenuItem key={typeOption}>
                        <Checkbox
                            value={typeOption}
                            checked={partyTypeFilter.indexOf(typeOption) > -1}
                            onChange={handlePartyTypeFilterChange}
                        />
                        <ListItemText primary={typeOption} />
                    </MenuItem>
                ))}
            </Menu>
        </FormControl>
    );
}

export default PartyFilter;