import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  useTheme,
} from "@mui/material";
import { Calendar, GeoAlt } from "react-bootstrap-icons";
import FestivalFavoriteButton from "./FestivalFavoriteButton";

function FestivalMapCard({ festival }) {
  const theme = useTheme();
  const s3BucketUrl = process.env.REACT_APP_AWS_S3_BUCKET_URL;

  return (
    <Card
      sx={{
        maxWidth: 300,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <a href={`${festival.website}`} target="_blank" rel="noopener noreferrer">
        <Box
          sx={{
            position: "relative",
            minWidth: "250px",
            height: "200px",
            overflow: "hidden",
          }}
        >
          <img
            src={
              festival.img !== "nan"
                ? festival.img
                : `${s3BucketUrl}/dancestyle_images/home-page-festivals.jpeg`
            }
            alt={festival.name}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "blur(3px)",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${s3BucketUrl}/dancestyle_images/home-page-festivals.jpeg`;
            }}
          />

          {/* Foreground Image (cover) */}
          <img
            src={
              festival.img !== "nan"
                ? festival.img
                : `${s3BucketUrl}/dancestyle_images/home-page-festivals.jpeg`
            }
            alt={festival.name}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain", // Cover the entire container, may crop
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${s3BucketUrl}/dancestyle_images/home-page-festivals.jpeg`;
            }}
          />
        </Box>
      </a>
      <CardContent>
        <Box sx={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}>
          <FestivalFavoriteButton festival={festival} />
        </Box>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            fontSize: 16,
            marginBottom: 1,
            maxWidth: 200,
          }}
        >
          {festival.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <GeoAlt color="blue" />
          {festival.location}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Calendar color="blue" />
          {festival.time}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 30,
          }}
        >
          <Typography variant="body2" color="text.secondary" component="div">
            Promo Code:
          </Typography>
          {festival.promocode !== "" ? (
            <>
              <Chip
                label={`${festival.promocode}`}
                color="primary"
                sx={{ mt: 0.5 }}
              />
            </>
          ) : (
            <Typography
              component="div"
              sx={{
                color: "red",
                fontSize: "12px",
                my: 0,
                lineHeight: 1,
              }}
            >
              Coming Soon
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default FestivalMapCard;
