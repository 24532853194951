import { Facebook, Instagram } from "@mui/icons-material";
import { Grid, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  // Hide elements on certain pages
  const hideElements =
    location.pathname === "/local_party" || location.pathname === "/festival";

  if (hideElements) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#333",
        color: "white",
        pb: 1,
      }}
    >
      <Grid item xs={{ sm: 12, md: 4 }}>
        {/* Copyright */}
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} DanceMaps. All rights reserved.
        </Typography>
      </Grid>

      <Grid item xs={{ sm: 12, md: 4 }}>
        {/* Legal Links */}
        <Grid container spacing={1}>
          <Grid item>
            <Link href="/privacy-policy" color="inherit" underline="hover">
              Privacy
            </Link>
          </Grid>
          <Grid item>
            <Link href="/terms-of-service" color="inherit" underline="hover">
              Terms
            </Link>
          </Grid>
          <Grid item>
            <Link href="/disclaimer" color="inherit" underline="hover">
              Disclaimer
            </Link>
          </Grid>
          <Grid item>
            <Link href="/impressum" color="inherit" underline="hover">
              Impressum
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={{ sm: 12, md: 4 }}>
        {/* Social Media Links */}
        <Grid container spacing={1}>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="Facebook"
              href="https://www.facebook.com/profile.php?id=61560910366260"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ padding: 0 }}
            >
              <Facebook />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="Instagram"
              href="https://instagram.com/dancemaps_official/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ padding: 0 }}
            >
              <Instagram />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
