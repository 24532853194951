// create a page for each dance style. The styles can be fetched. The page
// will be built with react and bootstrap that lists the dance style.

// Path: client/src/components/DanceStyle.js
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../store/store.js";
import "./DanceStyle.css";

function DanceStyle() {
  const [danceStyles, setDanceStyles] = useState([]);
  const navigate = useNavigate();

  const danceStore = useStore();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dancestyles/`)
      .then((response) => response.json())
      .then((data) => {
        setDanceStyles(data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
    // call here to prevent Infinity render
    danceStore.reset();
  }, []);

  const s3BucketUrl = process.env.REACT_APP_AWS_S3_BUCKET_URL;

  const handleStyleClick = (style) => {
    danceStore.setStyle(style);
    danceStore.setStarterFigure(style);
    danceStore.setCurrentFigure([]);
    danceStore.fetchNextFigure([]);
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          height: "60px",
          backgroundImage:
            "url(https://dancemap-bucket.s3.eu-central-1.amazonaws.com/dancestyle_images/home-page-wallpaper-aachener-weiher-mirrored.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "0px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          className="home-page-title"
          sx={{
            color: "white",
            marginLeft: 2,
            textShadow: "1px 1px 3px black",
          }}
        >
          Dance Dictionary
        </Typography>
      </Container>
      <Container sx={{ backgroundColor: "#faf8ff", paddingTop: 2 }}>
        <Typography
          variant="h7"
          className="home-page-subtitle"
          sx={{
            color: "black",
            textShadow: "1px 1px 3px white",
            fontWeight: "bold",
            backgroundColor: "#faf8ff",
          }}
        >
          Click on EXPLORE to navigate through the figures for each dance style
        </Typography>
      </Container>

      <Grid container spacing={2} justifyContent="center">
        {danceStyles.map((style) => {
          if (style.disabled) {
            return null;
          }

          return (
            <Grid
              item
              key={style._id}
              xs={12}
              sm={6}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card sx={{ maxWidth: 345 }}>
                <Box
                  component="div"
                  onClick={() => {
                    handleStyleClick(style.name);
                    navigate(`/${style.name}_map`);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={`${s3BucketUrl}/dancestyle_images/${style.name}.jpg`}
                    alt={`${style.name} Image`}
                    style={{ objectFit: "cover" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${s3BucketUrl}/dancestyle_images/festivals_alt.png`;
                    }}
                  />
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {style.name.replace(/_/g, " ")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <span style={{ fontWeight: "bold" }}>Featuring:</span>{" "}
                    {style.description}
                  </Typography>
                  <Button
                    component={Link}
                    to={`/${style.name}_map`}
                    onClick={() => handleStyleClick(style.name)}
                    variant="contained"
                    size="small"
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Explore
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default DanceStyle;
